import { SimplePlayer, PlayerStatus } from "./simple-player";
import { StatusStorage } from "./status-storage";
import { clientWidth, clientHeight, os, offsetRight, astrict, debounce, isInLeft} from "./utils";

interface AudioSource { id: number, url: string, img: string, ref: string } 

export interface GlobalPlayerStatus {
    position: { right: number, bottom: number }
    zIndex: number
    audio: null | AudioSource
    play: boolean
    currentTime: number
}

export interface GlobalPlayerPlaylist {
    content: AudioSource[]
}

export class GlobalPlayer {
    private static _instance: GlobalPlayer | null = null

    private btnW = 54
    private btnH = 54
    private circlePrgR = 22
    private circlePrgX = this.btnW / 2
    private circlePrgY = this.btnH / 2

    readonly player = new SimplePlayer(false)
    private readonly status: StatusStorage<GlobalPlayerStatus>
    private readonly playlist: StatusStorage<GlobalPlayerPlaylist>
    private el: HTMLDivElement | null = null

    private timeUpdateHandler: ((status: GlobalPlayerStatus) => any) | null = null

    constructor(storageKey: string,
        defaultStatus: GlobalPlayerStatus, storage: Storage = window.sessionStorage || window.localStorage) {
        if (GlobalPlayer._instance) {
            throw new Error('全局播放器只能初始化一次')
        }
        GlobalPlayer._instance = this

        this.status = new StatusStorage<GlobalPlayerStatus>(storageKey + '/Status', defaultStatus, storage)
        this.playlist = new StatusStorage<GlobalPlayerPlaylist>(storageKey + '/Playlist', { content: [] }, storage)
        this.player.on('timeUpdate', (currentTime: number) => {
            if (this.player.getStatus().status === 'playing' && currentTime >= 1) {
                this.status.set('currentTime', currentTime)
                typeof this.timeUpdateHandler === "function" && this.timeUpdateHandler(this.getStatus())
            }
        })

        this.player.on('ended', () => {
            this.nextAudio()
            this.setCurrentTime(0)
        })
    }

    private nextAudio() {
        const playlist = this.playlist.get('content')
        let next: AudioSource | null = null
        const current = this.status.get('audio')

        if (!current) {
            next = playlist[0] || null
        } else {
            for (let i = 0; i < playlist.length; i++) {
                const audio = playlist[i];
                if (audio.id === current.id) {
                    next = playlist[i + 1] || 0
                    continue
                }
            }
        }

        if (next) {
            this.setAudio(next, this.playlist.get('content'))
            this.player.load(next.url)
            const imgEl = document.getElementById('wk-global-player-cover-img')
            const btnContent = document.getElementById('wk-global-player-return-lecture-a') as HTMLAnchorElement
            if (imgEl && btnContent) {
                imgEl.style.backgroundImage = `url(${next.img})`
                btnContent.href = next.ref
            }
            this.player.play()
        }
    }

    onTimeUpdate(func: GlobalPlayer['timeUpdateHandler']) {
        this.timeUpdateHandler = func
    }
 
    getStatus() {
        return this.status.getAll()
    }

    setAudio(audio: GlobalPlayerStatus['audio'], playlist?: GlobalPlayerPlaylist['content']) {
        this.status.set('audio', audio)
        this.playlist.set('content', playlist || [])
        this.setCurrentTime(0)
    }

    setCurrentTime(currentTime: GlobalPlayerStatus['currentTime']) {
        this.status.set('currentTime', currentTime)
    }

    setPlay(b: boolean) {
        this.status.set('play', b)
    }

    play() {
        const { audio } = this.status.getAll()
        if (audio) {
            this.status.set('play', true)
            return this.player.play()
        }
    }

    pause() {
        const { audio } = this.status.getAll()
        if (audio) {
            this.status.set('play', false)
            return this.player.pause()
        }
    }

    public toggle = debounce(() => {
        const { status } = this.player.getStatus()
        const lectureEl = document.getElementById('wk-global-player-return-lecture')
        if (lectureEl) {
            if (status !== "pausing") {
                this.pause()

                lectureEl.style.opacity = '1'
                lectureEl.style.width = '181px'

                const { position } = this.getStatus()
                if (isInLeft(position.right)) {
                    lectureEl.className = 'wk-return-lecture-left'
                } else {
                    lectureEl.className = 'wk-return-lecture-right'
                }

                //3s后自动隐藏
                this.timer = window.setTimeout(() => {
                    lectureEl.style.opacity = '0'
                    lectureEl.style.width = '0px'
                }, 3000)
            } else {
                this.play()

                lectureEl.style.opacity = '0'
                lectureEl.style.width = '20px'
                clearTimeout(this.timer)
            }
        }
    }, 100)

    show() {
        if (!this.el) {
            this.player.init()
            this.render()
        }

        const { audio, play, currentTime } = this.status.getAll()
        const el = this.el
        const imgEl = document.getElementById('wk-global-player-cover-img')
        const btnContent =  document.getElementById('wk-global-player-return-lecture-a') as HTMLAnchorElement

        if (audio && el && imgEl && btnContent) {
            this.player.load(audio.url)
            imgEl.style.backgroundImage = `url(${audio.img})`
            btnContent.href = audio.ref

            if (currentTime) {
                this.player.seek(currentTime)
            }

            if (play) {
                this.player.play()
            }

            el.style.display = 'block'
        }
    }

    hide() {
        if (this.el) {
            this.player.pause()
            this.el.style.display = 'none'
        }
    }

    // 创建 dom
    private inMove: boolean = false
    private timer:number = 0
    private bindMove() {
        if (this.el) {
            const el = this.el

            let right = 0
            let bottom = 0
            let x0 = 0
            let y0 = 0

            const body = document.querySelector('body')

            const handleMouseDown = (_evt: MouseEvent | TouchEvent) => {
                const evt = _evt as any
                x0 = evt.x || (evt.x === 0 ? 0 : evt.touches[0].clientX)
                y0 = evt.y || (evt.y === 0 ? 0 : evt.touches[0].clientY)

                right = parseInt(el.style.right as string)
                bottom = parseInt(el.style.bottom as string)

                document.addEventListener('mousemove', handleMouseMove)
                document.addEventListener('mouseup', handleMouseUp)

                document.addEventListener('touchmove', handleMouseMove,{ passive: false })
                document.addEventListener('touchend', handleMouseUp)

                if(body){
                    body.style.overflow = 'hidden'
                    body.style.touchAction = 'none'

                    os.isIOS && _evt.preventDefault()
                }
            }

            const handleMouseMove = (_evt: MouseEvent | TouchEvent) => {

                const evt = _evt as any
                const dx = (evt.x || (evt.x === 0 ? 0 : evt.touches[0].clientX)) - x0
                const dy = (evt.y || (evt.y === 0 ? 0 : evt.touches[0].clientY)) - y0

                this.inMove = this.inMove || (Math.abs(dx) + Math.abs(dy) > 15)

                const newRight = astrict(Math.min(Math.max(right - dx, 0), clientWidth() - this.btnW))
                const newBottom = Math.min(Math.max(bottom - dy, 0), clientHeight() - this.btnH)

                el.style.right = `${newRight}px`
                el.style.bottom = `${newBottom}px`

                if(body){
                    body.style.overflow = 'hidden'
                    body.style.touchAction = 'none'

                    _evt.preventDefault()
                }
            }

            const handleMouseUp = () => {
                setTimeout(() => this.inMove = false, 30)
                document.removeEventListener('mousemove', handleMouseMove)
                document.removeEventListener('mouseup', handleMouseUp)

                document.removeEventListener('touchmove', handleMouseMove)
                document.removeEventListener('touchend', handleMouseUp)

                this.status.set('position', {
                    right: parseInt(el.style.right as string) - offsetRight(),
                    bottom: parseInt(el.style.bottom as string)
                })

                if(body){
                    body.style.overflow = 'auto'
                    body.style.touchAction = 'auto'
                }

                if (!this.inMove) {
                    this.toggle()
                }
            }

            el.addEventListener('mousedown', handleMouseDown)
            el.addEventListener('touchstart', handleMouseDown,{ passive: false })
        }
    }

    private bindEvent() {
        const progressEl = document.getElementById('wk-global-player-progress')
        const coverEl = document.getElementById('wk-global-player-cover')
        const coverImg = document.getElementById('wk-global-player-cover-img')
        if (progressEl) {
            this.player.on('timeUpdate', (t, d) => {
                const audioPercent = d!==0 ? Math.ceil(t * Math.PI * this.circlePrgR * 2 / d) : 0
                progressEl.innerHTML = `<circle
                  stroke-dasharray="${audioPercent},300"
                  cx="${this.circlePrgX}"
                  cy="${this.circlePrgY}"
                  r="${this.circlePrgR}"
                  stroke-width="2px"
                  stroke="#FF5232"
                  >
                </circle>`
            })
        }

        const statusEl = document.getElementById('wk-global-player-status')
        const lectureEl = document.getElementById('wk-global-player-return-lecture')
        const loadingImg = 'https://static-1253442168.file.myqcloud.com/xupload/20190618_loading.png'
        const pausingImg = 'https://static-1253442168.file.myqcloud.com/xupload/20190618_pausing.png'
        const playingImg = 'https://static-1253442168.file.myqcloud.com/xupload/20190618_play.png'
        if (statusEl) {
            this.player.on('status', (status: PlayerStatus) => {
                statusEl.innerHTML = `<img id="${status === 'loading' && 'wk-global-player-loading'}" src="${status === 'loading' ? loadingImg : status === 'playing' ? pausingImg : playingImg}" alt="" />`
                if (coverEl && coverImg && lectureEl) {
                    if (status === 'pausing') {
                        const iTransform = getComputedStyle(coverImg).transform
                        const cTransform = getComputedStyle(coverEl).transform
                        if(iTransform && cTransform){
                            coverEl.style.transform = cTransform === 'none'
                                ? iTransform
                                : iTransform.concat(' ', cTransform)
                        }
                        coverImg.classList.add('wk-global-player-cover-pause')
                        coverImg.classList.remove('wk-global-player-cover-play')
                    } else {
                        coverImg.classList.add('wk-global-player-cover-play')
                        coverImg.classList.remove('wk-global-player-cover-pause')
                    }
                }
            })

        }

        this.bindMove()

        const lectureaEl = document.getElementById('wk-global-player-return-lecture-a') as HTMLAnchorElement
        if(lectureaEl && os.isIOS){//修复ios a标签active不生效
            lectureaEl.addEventListener('touchstart', (e) => {
                lectureaEl.style.background = '#E4431B'
            })
            lectureaEl.addEventListener('touchend', (e) => {
                lectureaEl.style.background = '#FF5A31'
                location.assign(lectureaEl.href)
            })
        }

        const cancelGlobalPlayer = document.getElementById('wk-global-player-cancel')
        if(cancelGlobalPlayer){
            cancelGlobalPlayer.addEventListener('mousedown', (e) => {
                this.setAudio(null)
                this.hide()
            })
            cancelGlobalPlayer.addEventListener('touchend', (e) => {
                this.setAudio(null)
                this.hide()
            })
        }
    }

    private render() {
        if (this.el) { return }
        const style = document.createElement('style')
        const { img } = this.status.get('audio') || { img: '' }
        const { right, bottom } = this.status.get('position') || { right: 10, bottom: 10 }
        const zIndex = this.status.get('zIndex')

        style.innerHTML = `
        #wk-global-player {
            position: fixed;
            z-index: ${zIndex};
        }
        #wk-global-player-bg{
            width: ${this.btnW}px;
            height:${this.btnH}px;
            padding:5px;
            background:#fff;
            border-radius:100%;
            box-shadow: 0 3px 17px -11px #333;
            box-sizing: border-box;
            position: relative;
            z-index: 1;

            -webkit-user-select: none;
            user-select: none;
        }
        #wk-global-player-cover {
            position:relative;
            z-index:1;
        }
        #wk-global-player-cover-img{
            width: ${this.btnW - 10}px;
            height: ${this.btnH - 10}px;
            border-radius:100%;
            overflow:hidden;
            background-size:cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        .wk-global-player-cover-play{
            animation: rotation 30s infinite linear;
            animation-fill-mode: forwards;
            -webkit-animation: rotation 30s infinite linear;
            -webkit-animation-fill-mode: forwards;
            overflow:hidden;
        }

        .wk-global-player-cover-pause{
            animation-play-state: paused!important;
            -webkit-animation-play-state:paused!important;
        }

        #wk-global-player-cover img {
            width: 100%;
            height: 100%;

            user-drag: none;
            -webkit-user-drag: none;
        }

        #wk-global-player-status{
            width: ${this.btnW - 10}px;
            height: ${this.btnH - 10}px;
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
            -webkit-transform: translate(-50%,-50%);
            border-radius:100%;
            overflow:hidden;
            z-index:2;
            background-color:rgba(0,0,0,0.3);
            display: flex;
            display: -webkit-flex;
            justify-content: center;
            align-items: center;
            -webkit-justify-content: center;
            -webkit-align-items: center;
            cursor: pointer;

        }

        #wk-global-player-status img{
            width:44px;
            height:44px;
            cursor: pointer;

            user-drag: none;
            -webkit-user-drag: none;
        }

        #wk-global-player-progress{
            width: ${this.btnW}px;
            height: ${this.btnW}px;
            position: absolute;
            left: 0;
            top: 0;
            z-index:1;
        }
        #wk-global-player-progress circle{
            fill: none;
            stroke-linecap: round;
            transform: rotate(-90deg);
            transform-origin: 50% 50%;
            transition: 0.5s stroke-dasharray;
          }
          
        #wk-global-player-return-lecture{
            height:42px;
            padding:5px;
            position: absolute;
            right:11px;
            top:6px;
            box-shadow: 0 3px 8px 0 rgba(0,0,0,0.10);
            border-radius: 27px;
            box-sizing: border-box;
            background: #FFFFFF;
            z-index: 0;
            transition: 0.3s all;
            overflow:hidden;
        }
        #wk-global-player-return-lecture a{
            display:block;
            width:92px;
            background: #FF5A31;
            border-radius: 22px;
            color:#fff;
            font-size:14px;
            box-sizing: border-box;
            text-decoration:none;
            line-height:32px;
            text-align:center;
            cursor: pointer;
        }
        #wk-global-player-return-lecture a:active{
            color:#fff;
            background: #E4431B;
        }
        #wk-global-player-loading{
            animation: playLoadingMove 1s infinite linear;
            animation-fill-mode: forwards;
            -webkit-animation: playLoadingMove 1s infinite linear;
            -webkit-animation-fill-mode: forwards;
        }

        #wk-global-player-return-lecture.wk-return-lecture-left{ 
            right: unset;
            left: 11px;
        }
        #wk-global-player-return-lecture.wk-return-lecture-left a{
            margin-left:43px;
            float:left;
        }
        #wk-global-player-return-lecture.wk-return-lecture-left span{
            margin-left:10px;
            float:left;
        }
        #wk-global-player-return-lecture.wk-return-lecture-right a{
            float:right;
            margin-right:43px;
        }
        #wk-global-player-return-lecture.wk-return-lecture-right span{
            margin-right:10px;
            float:right;
        }
        
        #wk-global-player-cancel {
            display:block;
            padding:6px 0;
            width:20px;
            cursor: pointer;
            font-size:0;
        }
        #wk-global-player-cancel img{
            width:100%;
        }

        @keyframes rotation {
            100%{
               transform:rotate(1turn);
               -webkit-transform:rotate(1turn);
            }
        }
        @-webkit-keyframes rotation {
            100%{
               transform:rotate(1turn);
               -webkit-transform:rotate(1turn);
            }
        }
        @keyframes playLoadingMove {
            0%{
              transform: rotate(0);
            }
            50%{
              transform: rotate(180deg);
            }
            100%{
              transform: rotate(360deg);
            }
        }
        -webkit-@keyframes playLoadingMove {
            0%{
              transform: rotate(0);
            }
            50%{
              transform: rotate(180deg);
            }
            100%{
              transform: rotate(360deg);
            }
        }
        `

        this.el = document.createElement('div')
        this.el.id = 'wk-global-player'

        this.el.style.right = `${right + offsetRight()}px`
        this.el.style.bottom = `${bottom}px`
        this.el.style.display = 'none'

        this.el.innerHTML = `
            <div id="wk-global-player-bg">
                <div id="wk-global-player-status">
                    <img src="https://static-1253442168.file.myqcloud.com/xupload/20190618_play.png" alt="">
                </div>
                <svg id="wk-global-player-progress" version="1.1" xmlns="http://www.w3.org/2000/svg"></svg>
                <div id="wk-global-player-cover">
                    <div id="wk-global-player-cover-img" class="wk-global-player-cover-pause" style="background-image: url(${img})"></div>
                </div>
            </div>
            <div id="wk-global-player-return-lecture" style="opacity:0;width: 20px">
                <a id="wk-global-player-return-lecture-a" href="https://m.lizhiweike.com">返回课程</a>
                <span id="wk-global-player-cancel"><img src="https://static-1253442168.file.myqcloud.com/xupload/20190621_ico-cancel.png" alt=""></span>
            </div>
        `

        document.head.appendChild(style)
        document.body.appendChild(this.el)
        this.bindEvent()
    }
}